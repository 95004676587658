import {
  Card,
  CardContent,
  makeStyles,
  Box,
  Tooltip,
  IconButton,
  Zoom,
} from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import { useWeb3React } from "@web3-react/core";
import React from "react";

import { numberTest, sixDigitsFormatter, trunc } from "../../utils/formatters";
import Text from "../Misc/Text";

const InfoCard = ({ card, index }) => {
  const web3Context = useWeb3React();

  const classes = useStyles({ web3Context, index });
  console.log("Number(card.value) => ", card);

  return (
    <Card elevation={1} className={classes.card}>
      <CardContent className={classes.cardContent}>
        {card.text === "Fees Collected" ? (
          <img className={classes.image} src={card.icon} />
        ) : (
          <img
            className={classes.image}
            src={card.icon}
            style={{ height: "50%" }}
          />
        )}

        <Box className={classes.textContainer}>
          {web3Context.chainId === 80001 ||
          web3Context.chainId === 137 ||
          card.public ? (
            <Box>
              <Text
                fontSize={28}
                lineHeight={20}
                fontWeight={600}
                color="#fff"
                marginBottom={20}
                text={
                  Number(card.value) > 0 && !numberTest(card?.value)
                    ? sixDigitsFormatter(card?.value)
                    : Number(card.value)
                    ? trunc(card?.value)
                    : 0
                }
              />
            </Box>
          ) : (
            <Box className={classes.noValue}></Box>
          )}

          <Box display="flex" alignItems="center">
            <Text
              text={card.text}
              fontSize={18}
              lineHeight={24}
              textAlign="center"
            />
            {card.infoText && (
              <Tooltip
                title={card.infoText}
                arrow
                TransitionComponent={Zoom}
                customStyles={classes.text}
              >
                <IconButton aria-label="delete" className={classes.iconButton}>
                  <InfoOutlined style={{ color: "#fff", fontSize: 20 }} />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default InfoCard;

const useStyles = makeStyles((theme) => ({
  card: {
    background: theme.palette.cardBgColor,
    border: theme.palette.border,
    borderRadius: 25,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    boxShadow: "none",
    height: 230,
    minWidth: 200,
    [theme.breakpoints.down("md")]: {
      minWidth: "229px",
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: "258px",
    },
    [theme.breakpoints.up("md")]: {
      minWidth: "173px",
    },
    [theme.breakpoints.up("lg")]: {
      minWidth: "184px",
    },
    marginBottom: 16,
    [theme.breakpoints.up(1600)]: {
      width: 250,
      maxWidth: 250,
    },
  },
  iconButton: {
    padding: 0,
    marginLeft: 5,
  },
  value: {
    marginBottom: 20,
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  image: {
    alignItems: "center",
    width: 80,
    marginBottom: 24,
    height: 80,
  },
  text: {
    whiteSpace: "nowrap",
  },
  infoIcon: {
    padding: 0,
    paddingLeft: 12,
  },
  noValue: {
    height: 6,
    width: 40,
    background: theme.palette.themeColor1,
    marginBottom: ({ web3Context, index }) =>
      web3Context.chainId == undefined && index == 4 ? 10 : 20,
  },

  textContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: ({ web3Context, index }) =>
      web3Context.chainId == undefined &&
      (index == 2 || index == 3 || index == 4)
        ? 15
        : 0,
  },
}));
