import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfoImg from "../components/unstake_components/InfoImg.js";

import CustomModal from "../components/unstake_components/CustomModal.js";

import { Box } from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import { unStakeAmount } from "../contract/functions/functions";
import { setLoading, showSnackbar, getChainData } from "../redux";

import { Card, CardContent, makeStyles, Typography } from "@material-ui/core";

import { APP_TOKEN_NAME, APP_UNSTAKING_MESSAGE } from "../utils/constants.js";
import { InfoPng } from "../assets/index.js";
import AmountInput from "../components/stake_components/AmountInput.js";
import BlockBtn from "../components/stake_components/BlockBtn.js";

const Unstake = () => {
  const classes = useStyles();
  const context = useWeb3React();
  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state.ui);

  const networkSet = useSelector((state) => state.web3.networkSet);
  const minStakingAmount = useSelector(
    (state) => state.web3.data.minStakingAmount
  );
  const minStakingPeriod = useSelector(
    (state) => state.web3.data.minStakingPeriod
  );

  const stakingPeriodValue = useSelector(
    (state) => state.web3.data.stakingPeriodValue
  );

  const stakingPeriod = useSelector((state) => state.web3.data.stakingPeriod);

  const currentNetwork = useSelector((state) => state.web3.currentNetwork);

  const { approved } = useSelector((state) => state.web3.data);
  const userData = useSelector((state) => state.web3.data);

  const [userBalance, setUserBalance] = useState(0);
  const [userInput, setUserInput] = useState();

  const [inputErrText, setInputErrText] = useState("");
  const [showInputErr, setShowInputErr] = useState(false);

  const [open, setOpen] = useState(false);
  const [modalText, setModalText] = useState("");
  const [modalLoadText, setModalLoadText] = useState("");

  useEffect(() => {
    if (context.account && context.active && networkSet) {
      getChainDataFunc();
    }
  }, [context, networkSet, currentNetwork]);

  useEffect(() => {
    if (context?.account) {
      setUserInput("");
    }
  }, [currentNetwork, context]);

  const changeLoadingStatus = (flag) => {
    dispatch(setLoading(flag));
  };

  const getChainDataFunc = () => {
    dispatch(getChainData(context.account, null, context.chainId));
  };

  const onUnStake = async (value, totalStaked) => {
    console.log("onUnStake => ", value, totalStaked);
    value = Number(value);
    totalStaked = Number(totalStaked);
    if (value <= totalStaked) {
      let userStakeAmount = await unStakeAmount(
        value,
        context.account,

        changeLoadingStatus,
        setOpen,
        showSnackbarFunc,
        setUserInput,
        () =>
          dispatch(
            getChainData(context.account, currentNetwork, context.chainId)
          )
      );

      console.log("user stake => ", userStakeAmount);
    } else if (value > totalStaked) {
      console.log(typeof value);
      console.log(typeof totalStaked);
    }
  };

  const handleClickOpen = () => {
    console.log("handleClickOpen => ", {
      userInput,
      approved,
      modalLoadText,
      modalText,
    });

    setModalText(
      `Unstaking will automatically convert your d${APP_TOKEN_NAME} rewards into ${APP_TOKEN_NAME}`
    );
    setModalLoadText(`Unstaking ${userInput} ${APP_TOKEN_NAME}`);
    setOpen(true);
  };
  const handleClose = (value) => {
    setOpen(false);
  };

  let modalFunc = () => {
    onUnStake(userInput, userData.dafiStaked);
  };
  const showSnackbarFunc = (data, severity) => {
    dispatch(showSnackbar({ message: data, severity }));
  };

  const onChangeInput = (input) => {
    if (input.includes(" ")) {
      setUserInput("");
      return;
    }

    setInputErrText("");
    setShowInputErr(false);
    if (Number.isNaN(Number(input))) {
      // setInputErrText(`Please enter only valid values`);
      // setShowInputErr(true);
      setUserInput("");
    }
    if (Number(input) <= userData.dafiStaked && Number(input) !== 0) {
      setInputErrText("");
      setShowInputErr(false);
      setUserInput(input);
    } else {
      if (Number(input)) {
        setUserInput(input);
        setInputErrText(
          `The amount entered is greater than your total number of staked ${APP_TOKEN_NAME}`
        );
      } else if (Number(input) === 0) {
        setUserInput(input);
        setInputErrText("Unstake amount must be greater than zero");
      }
      setShowInputErr(true);
    }
  };

  const onMaxClick = () => {
    setShowInputErr(false);
    setInputErrText("");
    setUserInput(Number(userData.dafiStaked)?.toFixed(2));
  };

  return (
    <div className={classes.container}>
      <CustomModal
        open={open}
        onClose={handleClose}
        text={modalText}
        modalFunc={modalFunc}
        loadText={modalLoadText}
        approved={approved}
        stakingPeriod={stakingPeriod}
        stakingPeriodValue={stakingPeriodValue}
        minStakingPeriod={minStakingPeriod}
      />
      <Box>
        <div className={classes.stakeContainer}>
          <Card elevation={1} className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Typography className={classes.title}>Unstake</Typography>
              <div className={classes.horizontalLine}></div>

              <div className={classes.withdrawInfo}>
                <img src={InfoPng} className={classes.infoIcon} />
                {APP_UNSTAKING_MESSAGE}
              </div>
              <InfoImg
                stakedAmount={userData.dafiStaked}
                rewards={userData.rewards}
              />

              <AmountInput
                userBalance={userBalance}
                minStakingAmount={minStakingAmount}
                minStakingPeriod={minStakingPeriod}
                userInput={userInput}
                setUserInput={setUserInput}
                stakedAmount={userData.dafiStaked}
                inputErrText={inputErrText}
                showInputErr={showInputErr}
                setInputErrText={setInputErrText}
                setShowInputErr={setShowInputErr}
                rewards={userData.rewards}
                onChangeInput={onChangeInput}
                onMaxClick={onMaxClick}
                maxDisabled={userData?.dafiStaked <= 0}
              />

              <div className={classes.marginBtm}></div>
              <BlockBtn
                label={!loading ? "Unstake" : "Transaction Pending"}
                handleClick={handleClickOpen}
                disabled={
                  showInputErr ||
                  loading ||
                  (!userInput && currentNetwork !== 1) ||
                  !userInput ||
                  userInput <= 0 ||
                  !context.active
                }
              />
            </CardContent>
          </Card>
        </div>
      </Box>
    </div>
  );
};

export default Unstake;

const useStyles = makeStyles((theme) => ({
  card: {
    background: theme.palette.cardBgColor,

    border: "2px solid rgba(255, 255, 255, 0.13)",
    borderRadius: 25,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    boxShadow: "none",
    width: "473px",
    position: "relative",
    "@media(max-width:550px)": {
      width: "90%",
    },
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowY: "scroll",
    border: 0,
  },
  container: {
    height: "100%",
    width: "100%",
    "@media(max-width:550px)": {
      marginLeft: "-16px",
    },
    "@media(max-width:320px)": {
      marginLeft: "0px",
    },
  },
  horizontalLine: {
    borderBottom: "1px solid #ffffff1A",
    width: "120%",
    position: "absolute",
    top: "54px",
  },
  infoIcon: {
    width: "20px",
    marginRight: "10px",
    "@media(max-width:550px)": {
      marginBottom: "6px",
    },
  },

  marginBtm: {
    marginBottom: "13px",
  },

  title: {
    fontWeight: 600,
    fontSize: "18px",
    color: "#ffffff",
    marginBottom: "38px",
  },
  verticalLine: {
    borderLeft: "1px solid #ffffff1A",
    height: "52px",
    marginRight: "17px",
  },
  stakeContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  withdrawInfo: {
    backgroundColor: theme.palette.cardBgColor,
    border: theme.palette.border,
    color: "#FFF",
    padding: "14px",
    width: "100%",
    borderRadius: "10px",

    marginBottom: "20px",
    marginTop: "-10px",
    fontWeight: 300,
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    "@media(max-width:550px)": {
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
    },
  },
}));
