import MediumIconSvg from "../assets/polygen/akar-icons_medium-fill.png";
import TwitterIconSvg from "../assets/polygen/akar-icons_twitter-fill (1).png";
import LinkedInIconSvg from "../assets/polygen/cib_linkedin-in.png";
import TelegramIconSvg from "../assets/polygen/cib_telegram-plane.png";
import GithubIconSvg from "../assets/polygen/Group 13839.png";

import DiscordIconSvg from "../assets/SideBar/discord.svg";

import UniswapSvg from "../assets/market/uniswap-uni-logo 1.svg";
import AscendSvg from "../assets/market/AscendEx-Logo 1.svg";
import PancakeSwapSvg from "../assets/market/pancakeswap-cake-logo 1.svg";

import TrendingDownSvg from "../assets/market/trending_down.svg";
import TrendingUpSvg from "../assets/market/trending_up.svg";

import TwitterAvatarSvg from "../assets/market/twitter_avatar.svg";

import AddDafiSvg from "../assets/dashboard/dafi_add.svg";

import GasPumpSvg from "../assets/gas-pump.svg";

import TotalFeesCollectedUpdated from "../assets/dashboard/TotalFeesCollected.png";

import GateIOSVG from "../assets/market/gate-io.svg";

import DafiTwitterJpg from "../assets/polygen/app-twitter.jpg";

// Leaderboard Assets
import Master1Svg from "./leaderboard/master1.svg";
import Master2Svg from "./leaderboard/master2.svg";
import Master3Svg from "./leaderboard/master3.svg";

import CosmonautSvg from "./leaderboard/Cosmonaut.svg";
import ApolloSvg from "./leaderboard/Apollo.svg";
import LunarSvg from "./leaderboard/Lunar.svg";
import VoyagerSvg from "./leaderboard/Voyager.svg";
import PioneerSvg from "./leaderboard/Pioneer.svg";

import PolygenLogo from "./polygen/polygen-logo.png";

import PolygenSideBarLogo from "./polygen/polygen-sidebar-logo.svg";
import AppLogoMetamask from "./polygen/app-logo-metamask.png";

import FeesCollectedGeneric from "./polygen/Fees Collected.png";

import RewardGeneric from "./polygen/Reward.png";
import PotentialGeneric from "./polygen/Potential.png";

import YourStaked from "./polygen/your-stake.png";
import TotalStaked from "./polygen/total-stake.png";

import TotalStakedStake from "./polygen/total-stake-stake.png";

import RewardPolygen from "./polygen/reward-polygen.png";

import InfoPng from "./polygen/info.png";

import DashboardActive from "./polygen/dashboard-active.png";
import StakeActive from "./polygen/stake-active.png";
import MarketActive from "./polygen/market-active.png";
import PolygonPng from "./polygen/polygon.png";

let LockSvg =
  "https://dafiprotocol-assets.s3.amazonaws.com/assets/assets/super-pool/lock.svg";

let TotalDefiStaked =
  "https://dafiprotocol-assets.s3.amazonaws.com/assets/assets/dashboard/icon+1.png";
let FeesCollected =
  "https://dafiprotocol-assets.s3.amazonaws.com/assets/assets/dashboard/icon+3.png";
let DafiStaked =
  "https://dafiprotocol-assets.s3.amazonaws.com/assets/assets/dashboard/icon+2.png";
let DafiRewards =
  "https://dafiprotocol-assets.s3.amazonaws.com/assets/assets/dashboard/icon+4.png";
let Potential =
  "https://dafiprotocol-assets.s3.amazonaws.com/assets/assets/dashboard/icon+5.png";

let CopyIcon =
  "https://dafiprotocol-assets.s3.amazonaws.com/assets/assets/dashboard/copy+1.svg";

let TopUser =
  "https://dafiprotocol-assets.s3.amazonaws.com/assets/assets/dashboard/Group+13471.svg";
let DashboardActiveIcon =
  "https://dafiprotocol-assets.s3.amazonaws.com/assets/assets/dashboard/dashboard+menu+icon.png";

let DashboardDisabled =
  "https://dafiprotocol-assets.s3.amazonaws.com/assets/assets/dashboard/Group+13481.png";
let StakeDisabled =
  "https://dafiprotocol-assets.s3.amazonaws.com/assets/assets/dashboard/Stake+disabled+icon.svg";
let SuperPoolDisabled =
  "https://dafiprotocol-assets.s3.amazonaws.com/assets/assets/dashboard/Super+pool+disabled+icon.svg";
let MarketDisabled =
  "https://dafiprotocol-assets.s3.amazonaws.com/assets/assets/dashboard/Market+disabled+icon.svg";
let SwapDisabled =
  "https://dafiprotocol-assets.s3.amazonaws.com/assets/assets/dashboard/swap+dissabled+icon.svg";

let SwapActiveIcon =
  "https://dafiprotocol-assets.s3.amazonaws.com/assets/assets/dashboard/Swap+Active.svg";
let StakeActiveIcon =
  "https://dafiprotocol-assets.s3.amazonaws.com/assets/assets/dashboard/Stake+Active.svg";
let SuperPoolActiveIcon =
  "https://dafiprotocol-assets.s3.amazonaws.com/assets/assets/dashboard/Superpool+Active.svg";
let MarketActiveIcon =
  "https://dafiprotocol-assets.s3.amazonaws.com/assets/assets/dashboard/Market+Active.svg";

let DafiLogo =
  "https://dafiprotocol-assets.s3.amazonaws.com/assets/assets/dashboard/logo.svg";

let InfoIcon =
  "https://dafiprotocol-assets.s3.amazonaws.com/assets/assets/dashboard/Group+13482.svg";
let BinanceIcon =
  "https://dafiprotocol-assets.s3.amazonaws.com/assets/assets/dashboard/binance-coin-bnb-logo+1.svg";
let EthereumIcon =
  "https://dafiprotocol-assets.s3.amazonaws.com/assets/assets/dashboard/ethereum.svg";
let MaticIcon =
  "https://dafiprotocol-assets.s3.amazonaws.com/assets/assets/dashboard/polygon-matic-logo+1.svg";
let WarningIcon =
  "https://dafiprotocol-assets.s3.amazonaws.com/assets/assets/dashboard/warning+1.svg";
let TelegramIcon =
  "https://dafiprotocol-assets.s3.amazonaws.com/assets/assets/dashboard/telegram.svg";
let TwitterIcon =
  "https://dafiprotocol-assets.s3.amazonaws.com/assets/assets/dashboard/twitter.svg";
let MailIcon =
  "https://dafiprotocol-assets.s3.amazonaws.com/assets/assets/dashboard/mail.svg";
let DocsIcon =
  "https://dafiprotocol-assets.s3.amazonaws.com/assets/assets/dashboard/docs.svg";
let MetaMaskIcon =
  "https://dafiprotocol-assets.s3.amazonaws.com/assets/assets/dashboard/meta-mask.svg";
let TrustWalletIcon =
  "https://dafiprotocol-assets.s3.amazonaws.com/assets/assets/dashboard/trust-wallet.svg";

let Ethereum2 =
  "https://dafiprotocol-assets.s3.amazonaws.com/assets/assets/super-pool/ethereum.svg";
let Matic2 =
  "https://dafiprotocol-assets.s3.amazonaws.com/assets/assets/super-pool/matic.svg";
let Binance2 =
  "https://dafiprotocol-assets.s3.amazonaws.com/assets/assets/super-pool/binance.svg";

let CarouselLeftArrow =
  "https://dafiprotocol-assets.s3.amazonaws.com/assets/assets/super-pool/Polygon+1.svg";
let CarouselRightArrow =
  "https://dafiprotocol-assets.s3.amazonaws.com/assets/assets/super-pool/Polygon+2.svg";

let TopUser1 =
  "https://dafiprotocol-assets.s3.amazonaws.com/assets/assets/dashboard/circle1.svg";
let TopUser2 =
  "https://dafiprotocol-assets.s3.amazonaws.com/assets/assets/dashboard/circle-2.svg";
let TopUser3 =
  "https://dafiprotocol-assets.s3.amazonaws.com/assets/assets/dashboard/circle-3.svg";
let TopUser4 =
  "https://dafiprotocol-assets.s3.amazonaws.com/assets/assets/dashboard/circle-4.svg";
let TopUser5 =
  "https://dafiprotocol-assets.s3.amazonaws.com/assets/assets/dashboard/circle-5.svg";

let ChainSwapLogo =
  "https://dafiprotocol-assets.s3.amazonaws.com/assets/assets/swap/chainswap-logo.6676e141 1.svg";

let MarketComingSoon =
  "https://dafiprotocol-assets.s3.amazonaws.com/assets/assets/market/coming-soon.svg";

export {
  LockSvg,
  DashboardDisabled,
  MetaMaskIcon,
  TrustWalletIcon,
  InfoIcon,
  TotalDefiStaked,
  FeesCollected,
  DafiStaked,
  DafiRewards,
  Potential,
  CopyIcon,
  TopUser,
  TelegramIcon,
  TwitterIcon,
  DocsIcon,
  MailIcon,
  DashboardActiveIcon,
  StakeDisabled,
  SuperPoolDisabled,
  MarketDisabled,
  SwapDisabled,
  DafiLogo,
  BinanceIcon,
  EthereumIcon,
  MaticIcon,
  WarningIcon,
  SwapActiveIcon,
  StakeActiveIcon,
  SuperPoolActiveIcon,
  MarketActiveIcon,
  Ethereum2,
  Matic2,
  Binance2,
  CarouselLeftArrow,
  CarouselRightArrow,
  TopUser1,
  TopUser2,
  TopUser3,
  TopUser4,
  TopUser5,
  ChainSwapLogo,
  MarketComingSoon,
  UniswapSvg,
  AscendSvg,
  PancakeSwapSvg,
  TrendingDownSvg,
  TrendingUpSvg,
  TwitterAvatarSvg,
  AddDafiSvg,
  MediumIconSvg,
  TwitterIconSvg,
  LinkedInIconSvg,
  TelegramIconSvg,
  GithubIconSvg,
  GasPumpSvg,
  DiscordIconSvg,
  TotalFeesCollectedUpdated,
  GateIOSVG,
  DafiTwitterJpg,
  Master1Svg,
  Master2Svg,
  Master3Svg,
  CosmonautSvg,
  ApolloSvg,
  LunarSvg,
  VoyagerSvg,
  PioneerSvg,
  PolygenLogo,
  PolygenSideBarLogo,
  FeesCollectedGeneric,
  RewardGeneric,
  PotentialGeneric,
  DashboardActive,
  StakeActive,
  MarketActive,
  YourStaked,
  TotalStaked,
  InfoPng,
  TotalStakedStake,
  RewardPolygen,
  PolygonPng,
  AppLogoMetamask,
};
