import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MailIcon from "@material-ui/icons/Mail";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import { useHistory, useLocation } from "react-router-dom";
import {
  DafiLogo,
  DashboardDisabled,
  MarketDisabled,
  StakeDisabled,
  SuperPoolDisabled,
  MarketActiveIcon,
  TwitterIconSvg,
  MediumIconSvg,
  TelegramIconSvg,
  LinkedInIconSvg,
  GithubIconSvg,
  PolygenSideBarLogo,
  DashboardActive,
  StakeActive,
  MarketActive,
} from "../../assets";
import { Box, Link } from "@material-ui/core";

const drawerWidth = 240;

function ResponsiveDrawer(props) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const history = useHistory();

  const location = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  let menuItems = [
    {
      text: "Dashboard",
      path: "/",
      icon: DashboardDisabled,
      activeIcon: DashboardActive,
    },
    {
      text: "Stake",
      path: "/stake",
      icon: StakeDisabled,
      activeIcon: StakeActive,
    },
    {
      text: "Superpool",
      path: "/super-pool",
      icon: SuperPoolDisabled,
      activeIcon: MarketActive,
    },

    // {
    //   text: "Leaderboard",
    //   path: "/leaderboard",
    //   icon: SwapDisabled,
    //   activeIcon: SwapActiveIcon,
    // },
    // {
    //   text: "Bridge",
    //   path: "/swap",
    //   icon: SwapDisabled,
    //   activeIcon: SwapActiveIcon,
    // },
  ];

  console.log("loction pathname> ", location.pathname);

  const drawer = (
    <div className={classes.drawerContainer}>
      <div>
        <div
          style={{ padding: "24px 16px", marginBottom: 16 }}
          onClick={() => history.push("/")}
        >
          <img src={PolygenSideBarLogo} style={{ height: 40 }} />
        </div>

        <List>
          {menuItems.map((item) => (
            <Box display="flex" marginBottom="16px">
              <div
                className={
                  location.pathname == item.path
                    ? classes.activeBar
                    : classes.inActiveBar
                }
              ></div>
              <ListItem
                button
                key={item.text}
                onClick={() => {
                  setMobileOpen(false);
                  history.push(item.path);
                }}
                className={
                  location.pathname == item.path ? classes.active : null
                }
              >
                <ListItemIcon style={{ minWidth: 40 }}>
                  {location.pathname === item.path ? (
                    <img
                      src={item.activeIcon}
                      style={{ height: 25, width: 25 }}
                    />
                  ) : (
                    <img src={item.icon} style={{ height: 25, width: 25 }} />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      className={
                        location.pathname === item.path
                          ? classes.listItemTextActive
                          : classes.listItemText
                      }
                    >
                      {item.text}
                    </Typography>
                  }
                />
              </ListItem>
            </Box>
          ))}
        </List>
      </div>

      <div className={classes.sideBarFooter}>
        <div className={classes.sideBarIconsContainer}>
          <Link
            className={classes.sideBarIcon}
            href="https://twitter.com/Polygen_io"
            target="_blank"
          >
            <img src={TwitterIconSvg} style={{ height: 18, width: 18 }} />
          </Link>
          <Link
            className={classes.sideBarIcon}
            href="https://medium.com/@polygen"
            target="_blank"
          >
            <img src={MediumIconSvg} style={{ height: 18, width: 18 }} />
          </Link>
          <Link
            className={classes.sideBarIcon}
            href="https://t.me/polygen_launchpad"
            target="_blank"
          >
            <img src={TelegramIconSvg} style={{ height: 18, width: 18 }} />
          </Link>
          <Link
            className={classes.sideBarIcon}
            href="https://www.linkedin.com/company/polygen-launchpad"
            target="_blank"
          >
            <img src={LinkedInIconSvg} style={{ height: 18, width: 18 }} />
          </Link>
          {/* 
          <Link
            className={classes.sideBarIcon}
            href="https://www.linkedin.com/company/dafiprotocol"
            target="_blank"
            style={{ marginRight: 0 }}
          >
            <img src={GithubIconSvg} style={{ height: 18, width: 18 }} />
          </Link> */}
        </div>
        {/* <div className={classes.copyRightText}>&copy; 2021 DAFI Protocol</div> */}
      </div>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbarStyles}>
          <div style={{ padding: "24px 16px" }}>
            <img src={PolygenSideBarLogo} />
          </div>

          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            variant="temporary"
            onEscapeKeyDown={handleDrawerToggle}
            onBackdropClick={handleDrawerToggle}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden mdDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("lg")]: {
      width: drawerWidth,
      flexShrink: 0,
    },

    background: theme.palette.cardBgColor,
  },
  appBar: {
    [theme.breakpoints.up("lg")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      display: "none",
    },

    display: "flex",

    background: "#0F0824",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },

  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  toolbarStyles: {
    display: "flex",
    justifyContent: "space-between",
  },
  drawerPaper: {
    width: drawerWidth,

    background: "linear-gradient(142.4deg, #380490 1.19%, #343434 114.83%)",

    border: "1px solid rgba(230, 231, 233, 0.1)",
  },
  title: {
    padding: theme.spacing(2),
    color: "#fff",
  },

  listItemText: {
    color: "rgba(255,255,255,.7)",
    fontSize: 18,
    lineHeight: "27px",
    fontWeight: 400,
  },

  listItemTextActive: {
    color: theme.palette.themeColor1,
    fontSize: 18,
    lineHeight: "27px",
    fontWeight: 600,
  },

  active: {
    color: "#fff",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },

  activeBar: {
    width: "7px",
    backgroundColor: theme.palette.themeColor1,
    height: 60,
    borderBottomRightRadius: 8,
    borderTopRightRadius: 8,
  },

  inActiveBar: {
    borderLeft: "7px solid transparent",
    marginBottom: 16,
  },

  sideBarIcon: {
    height: 30,
    width: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.themeColor1,
    borderRadius: 8,
    marginRight: 10,
    cursor: "pointer",
  },

  sideBarIconsContainer: {
    display: "flex",

    alignItems: "center",
    marginBottom: "16px",
  },

  sideBarFooter: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },

  drawerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    marginBottom: "50px",
  },

  copyRightText: {
    fontSize: 14,
    lineHeight: "16px",
    color: "#fff",
  },
}));
