import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { ThemeProvider } from "@material-ui/core/styles";

import "./App.css";
import Layout from "./components/Layout";
import Dashboard from "./containers/Dashboard";

import { Web3ReactProvider } from "@web3-react/core";

import { getLibrary } from "./utils/getLibrary";

import Snackbar from "./components/Snackbar";

import SuperPool from "./containers/SuperPool";

import Market from "./containers/Market";
import AppContainer from "./containers/AppContainer";

import MarketX from "./containers/MarketX";

import { theme } from "./theme";

function App() {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <ThemeProvider theme={theme}>
        <Router>
          <Layout>
            <Snackbar />

            <Switch>
              <Route exact path="/" component={Dashboard} />

              <Route exact path="/stake" component={AppContainer} />
              <Route exact path="/super-pool" component={SuperPool} />
              <Route exact path="/swap" component={MarketX} />
              <Route exact path="/market" component={Market} />
            </Switch>
          </Layout>
        </Router>
      </ThemeProvider>
    </Web3ReactProvider>
  );
}

export default App;
