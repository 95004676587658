import * as contract from "../../contract/functions/functions";
import fetch from "../../utils/fetch";

import FetchTwitter from "../../utils/FetchTwitter";
import { getTimezoneOffset } from "../../utils/formatters";
import * as web3Types from "../types/web3Types";

import {
  API_BASE_URL,
  API_DEV_BASE_URL,
  GRAPH_BASE_URL,
} from "../../contract/constants";
import moment from "moment";
import {
  COIN_GECKO_BASE_URL,
  COIN_GECKO_COIN_ID,
  ETHERSCAN_API_KEY,
  ETHERSCAN_BASE_URL,
  TOP_FIVE_USERS_QUERY,
  TX_HISTORY_QUERY,
} from "../../utils/constants";

export const getChainData = (account, currentNetwork, chainId) => {
  return async (dispatch) => {
    dispatch(setAppLoading(true));
    dispatch(getDafiStaked(account));

    if (currentNetwork) {
      dispatch(getRewards(account, currentNetwork, chainId));
      dispatch(getPotential(account, currentNetwork));
      dispatch(getTokenBalance(account));
    }
    dispatch(getMinimumStakingAmount());
    dispatch(getMinimumStakingPeriod());
    dispatch(calculateStakingPeriod(account));
  };
};

/* =============================================================================  */
// Get data from the contract

export const getCoinDemandFactor = (currentNetwork) => async (dispatch) => {
  try {
    let demandFactorV2 = await contract.getDemandFactorV2(currentNetwork);

    dispatch({
      type: web3Types.SET_CHAIN_DATA,
      payload: {
        demandFactorV2: demandFactorV2 / 100000000,
      },
    });
  } catch (error) {
    console.log("getCoinDemandFactor Error => ", error);
  }
};

export const getDafiStaked = (account) => {
  return async (dispatch) => {
    try {
      let res = await contract.totalAmountStaked(account);
      console.log("Response getDafiStaked => ", res);
      dispatch({
        type: web3Types.SET_CHAIN_DATA,
        payload: { dafiStaked: res },
      });
    } catch (error) {
      console.log("Error => ", error);
      dispatch(setAppLoading(false));
    }
  };
};

export const getTotalFeesDeposited = () => {
  return async (dispatch) => {
    try {
      let res = await contract.getTotalFeesCollectedV2();
      console.log("Response getTotalFeesCollectedV2 => ", res);
      dispatch({
        type: web3Types.SET_CHAIN_DATA,
        payload: { feesCollected: res },
      });
    } catch (error) {
      console.log("Error => ", error);
      dispatch(setAppLoading(false));
    }
  };
};

export const getTotalDafiStaked = () => {
  return async (dispatch) => {
    try {
      let res = await contract.totalOverallAmountStaked();
      console.log("Response getTotalDafiStaked => ", res);
      dispatch({
        type: web3Types.SET_CHAIN_DATA,
        payload: { totalDafiStaked: res },
      });
    } catch (error) {
      console.log("Error => ", error);
      dispatch(setAppLoading(false));
    }
  };
};

export const getMinimumStakingAmount = () => {
  return async (dispatch) => {
    try {
      let res = await contract.getMinimumStakeAmount();
      console.log("Response getMinimumStakingAmount => ", res);
      dispatch({
        type: web3Types.SET_CHAIN_DATA,
        payload: { minStakingAmount: res },
      });
    } catch (error) {
      console.log("Error => ", error);
      dispatch(setAppLoading(false));
    }
  };
};

export const getMinimumStakingPeriod = () => {
  console.log("coming here getMinimumStakingPeriod");
  return async (dispatch) => {
    try {
      let res = await contract.getMinimumStakePeriod();
      console.log("Response getMinimumStakingPeriod => ", res);
      dispatch({
        type: web3Types.SET_CHAIN_DATA,
        payload: { minStakingPeriod: res },
      });
    } catch (error) {
      console.log("Error => ", error);
      dispatch(setAppLoading(false));
    }
  };
};

export const getRewards = (account, currentNetwork, chainId, input = 0) => {
  return async (dispatch) => {
    console.log("getRewards start => ", account, currentNetwork);
    try {
      console.log("Response getRewardBalanceV2 => ");

      let res = await contract.getRewardBalanceV2Binance(
        account,
        currentNetwork,
        chainId,
        input
      );

      console.log("Response getRewardBalanceV2 final=> ", res);
      dispatch({
        type: web3Types.SET_CHAIN_DATA,
        payload: { rewards: res, rewardsPartial: res },
      });
    } catch (error) {
      console.log("Error => ", error);
      dispatch(setAppLoading(false));
    }
  };
};

const calculateStakingPeriod = (account) => {
  return async (dispatch) => {
    try {
      console.log("Response calculateStakingPeriod => ");
      let { createdOn } = await contract.getStakeDetailsV2(account);

      let minStakingPeriod = await contract.getMinimumStakePeriod();

      console.log("Response calculateStakingPeriod final=> ", {
        createdOn,
        minStakingPeriod,
      });

      let currentTimestamp = Date.now() / 1000;

      console.log(currentTimestamp - createdOn >= minStakingPeriod);

      dispatch({
        type: web3Types.SET_CHAIN_DATA,
        payload: {
          stakingPeriod: currentTimestamp - createdOn >= minStakingPeriod,
          stakingPeriodValue: createdOn,
          minStakingPeriod,
        },
      });

      dispatch(setAppLoading(false));
    } catch (error) {
      console.log("Error => ", error);
      dispatch(setAppLoading(false));
    }
  };
};

export const getPotential = (account, currentNetwork) => {
  return async (dispatch) => {
    try {
      let res = await contract.getPotentialBalanceV2(account, currentNetwork);
      console.log("Response getPotentialBalanceV2 => ", res);
      dispatch({
        type: web3Types.SET_CHAIN_DATA,
        payload: { potential: res },
      });
    } catch (error) {
      console.log("Error => ", error);
      dispatch(setAppLoading(false));
    }
  };
};

/* =============================================================================  */
// Subgraph/Backend routes
export const getTopFiveUsers = (chainId, network) => {
  console.log("getTopFiveUsers => ", { chainId, network });
  return async (dispatch) => {
    try {
      let url = "";

      if (chainId) {
        if (chainId === 80001) {
          url = `${GRAPH_BASE_URL}/dafi-polygon-subgraphv2`;
        } else if (chainId === 137) {
          url = `${GRAPH_BASE_URL}/pgensubgraph`;
        }
      }

      let responseData = await fetch(url, {
        query: TOP_FIVE_USERS_QUERY,
      });

      console.log("getTopFiveUsers response => ", responseData);

      let sortedArray = [...responseData.payload.data.stakes].sort(function (
        x,
        y
      ) {
        return contract.fromWei(y.amount) - contract.fromWei(x.amount);
      });

      dispatch({
        type: web3Types.SET_CHAIN_DATA,
        payload: {
          topFiveUsers: sortedArray.slice(0, 5),
        },
      });
    } catch (error) {
      console.log("Error => ", error);
    }
  };
};

export const getTransactionsHistory = (chainId, network) => {
  console.log({ chainId, network });
  return async (dispatch) => {
    try {
      let url = "";

      if (chainId) {
        if (chainId === 80001 && network === 4) {
          url = `${GRAPH_BASE_URL}/dafi-polygon-subgraphv2`;
        } else if (chainId === 137 && network === 3) {
          url = `${GRAPH_BASE_URL}/pgensubgraph`;
        }
      }

      console.log("getTransactionsHistory url => ", url);

      let response = await fetch(url, {
        query: TX_HISTORY_QUERY,
      });

      let sortedArray = [...response.payload.data.stakehistories].sort(
        function (x, y) {
          return y.time * 1000 - x.time * 1000;
        }
      );

      console.log("getTransactionsHistory => ", response);
      dispatch({
        type: web3Types.SET_CHAIN_DATA,
        payload: {
          transactionsHistory: sortedArray,
        },
      });
    } catch (error) {
      console.log("Error => ", error);
    }
  };
};

export const getGraphData =
  (account, chainId, currentNetwork, filter = "day") =>
  async (dispatch) => {
    console.log("getGraphData start => ", { chainId, currentNetwork });
    try {
      let url = `${API_BASE_URL}/network-demand/${filter}/${getTimezoneOffset()}/${account}`;

      if (chainId) {
        if (chainId === 80001) {
          url = `${API_BASE_URL}/polygon-testnet/${filter}/${getTimezoneOffset()}/${account}`;
        } else if (chainId === 137) {
          url = `${API_BASE_URL}/polygon-v2/${filter}/${getTimezoneOffset()}/${account}`;
        }
      }
      console.log("getGraphData url => ", url);

      const { payload, status } = await fetch(url, null, "get");

      console.log("getGraphData => ", payload, status);

      dispatch({
        type: web3Types.SET_CHAIN_DATA,
        payload: {
          graphData: {
            timeArray: [...payload.timedataForWeekay.sort((x, y) => x - y)],
            rewardBalanceArray: [
              ...payload.rewardBalancedataForWeekay.map((val) => {
                let formatted = val?.toFixed(20);

                console.log("cool => ", typeof formatted);

                return Number(formatted)?.toFixed(5);
              }),
            ],
          },
        },
      });
    } catch (error) {
      console.log("getGraphData Error => ", error);
    }
  };

export const getSuperPoolGraphData = (chainId) => async (dispatch) => {
  try {
    let url = "";

    if (chainId) {
      {
        url = `${API_BASE_URL}/polygon-v2/superpool/day/${getTimezoneOffset()}`;

        const { payload, status } = await fetch(url, null, "get");

        console.log(
          "getSuperPoolGraphData polygon-mainnet => ",
          payload,
          status
        );

        dispatch({
          type: web3Types.SET_CHAIN_DATA,
          payload: {
            superPoolGraphDataMaticV2: {
              timeArray: [...payload.timedataForWeekay.sort((x, y) => x - y)],
              rewardBalanceArray: [
                ...payload.rewardBalancedataForWeekay.map((val) => {
                  // let formatted = val?.toFixed(20);

                  return Number(val)?.toFixed(2);
                }),
              ],
            },
          },
        });
      }
    } else {
    }
  } catch (error) {
    console.log("getGraphData Error => ", error);
  }
};

export const getTotalNetworkStakes = (chainId) => async (dispatch) => {
  console.log("chainId => ", chainId);
  try {
    let url = "";

    url = `${API_BASE_URL}/polygon-v2/getAllNetworksDafiStake`;

    const { payload, status } = await fetch(url, null, "get");

    console.log("getTotalTestnetStakes => ", payload, status);

    dispatch({
      type: web3Types.SET_CHAIN_DATA,
      payload: {
        OverallTotalStakes: payload,
      },
    });
  } catch (error) {
    console.log("getTotalTestnetStakes Error => ", error);
  }
};

export const getOverallAPY = (chainId, account) => async (dispatch) => {
  console.log("chainId => ", chainId);
  try {
    let url = "";

    url = `${API_BASE_URL}/polygon-v2/getAPY/${account}`;

    const { payload, status } = await fetch(url, null, "get");

    console.log("getOverallAPY => ", payload, status);

    dispatch({
      type: web3Types.SET_CHAIN_DATA,
      payload: {
        overallAPY: payload,
      },
    });
  } catch (error) {
    console.log("getOverallAPY Error => ", error);
  }
};

export const getOverAllPotentialAPY =
  (chainId, account, currentNetwork) => async (dispatch) => {
    console.log("getOverAllPotentialAPY chainId => ", chainId);
    try {
      let url = "";

      url = `${API_BASE_URL}/polygon-v2/getPotentialAPY/${account}`;

      const { payload, status } = await fetch(url, null, "get");

      console.log("getOverAllPotentialAPY => ", payload, status);

      dispatch({
        type: web3Types.SET_CHAIN_DATA,
        payload: {
          overallPotentialAPY: payload,
        },
      });
    } catch (error) {
      console.log("getOverAllPotentialAPY Error => ", error);
    }
  };

export const getTotalWalletHoldersCount = () => async (dispatch) => {
  try {
    const { payload, status } = await fetch(
      `${API_BASE_URL}/market/getWalletHolders`,
      null,
      "get"
    );

    console.log("getTotalWalletHoldersCount => ", payload, status);

    dispatch({
      type: web3Types.SET_CHAIN_DATA,
      payload: {
        walletHolders: payload,
      },
    });
  } catch (error) {
    console.log("getTotalWalletHoldersCount Error => ", error);
  }
};
export const getTwitterTweets = () => async (dispatch) => {
  try {
    console.log("getTwitterTweets start");
    const { payload, status } = await FetchTwitter(
      `${API_BASE_URL}/market/getTweets`,
      null,
      "get"
    );

    console.log("getTwitterTweets => ", payload);

    dispatch({
      type: web3Types.SET_CHAIN_DATA,
      payload: {
        twitterData: payload,
      },
    });
  } catch (error) {
    console.log("getTwitterTweets Error => ", error);
  }
};

/* =============================================================================  */
// Third Party APIs
export const getCoinMarketChart = (timestamp) => async (dispatch) => {
  try {
    const { payload, status } = await fetch(
      `${COIN_GECKO_BASE_URL}/coins/${COIN_GECKO_COIN_ID}/market_chart/range?vs_currency=usd&from=${timestamp}&to=${moment()
        .subtract("hours")
        .unix()}`,
      null,
      "get"
    );

    console.log("getCoinMarketChart => ", payload, status);

    dispatch({
      type: web3Types.SET_CHAIN_DATA,
      payload: {
        marketGraph: payload,
      },
    });
  } catch (error) {
    console.log("getCoinMarketChart Error => ", error);
  }
};

export const getCoinCurrentPrice = () => async (dispatch) => {
  try {
    const { payload, status } = await fetch(
      `${COIN_GECKO_BASE_URL}/simple/price?ids=${COIN_GECKO_COIN_ID}&vs_currencies=usd&include_24hr_vol=true&include_24hr_change=true`,
      null,
      "get"
    );

    console.log("getCoinCurrentPrice => ", payload, status);

    dispatch({
      type: web3Types.SET_CHAIN_DATA,
      payload: {
        coinCurrentPrice: payload[COIN_GECKO_COIN_ID].usd,
        coin24hVolume: payload[COIN_GECKO_COIN_ID].usd_24h_vol,
        coin24hPriceChange: payload[COIN_GECKO_COIN_ID].usd_24h_change,
      },
    });
  } catch (error) {
    console.log("getCoinCurrentPrice Error => ", error);
  }
};

export const getCoinOtherData = () => async (dispatch) => {
  try {
    const { payload, status } = await fetch(
      `${COIN_GECKO_BASE_URL}/coins/markets?vs_currency=usd&ids=${COIN_GECKO_COIN_ID}&order=market_cap_desc&per_page=100&page=1&sparkline=true&price_change_percentage=7d`,
      null,
      "get"
    );

    console.log("getCoinOtherData => ", payload, status);

    dispatch({
      type: web3Types.SET_CHAIN_DATA,
      payload: {
        sevenDayHighLow: [
          Math.max.apply(null, payload[0].sparkline_in_7d.price),
          Math.min.apply(null, payload[0].sparkline_in_7d.price),
        ],
      },
    });
  } catch (error) {
    console.log("getCoinOtherData Error => ", error);
  }
};

export const getGasEstimationPrice = () => async (dispatch) => {
  try {
    console.log("getGasEstimationPrice start");
    const { payload, status } = await fetch(
      `${ETHERSCAN_BASE_URL}/api?module=gastracker&action=gasoracle&apikey=${ETHERSCAN_API_KEY}`,
      null,
      "get"
    );

    console.log("getGasEstimationPrice => ", payload);

    dispatch({
      type: web3Types.SET_GAS_PRICE,
      payload: {
        fastGasPrice: payload.result.FastGasPrice,
        safeGasPrice: payload.result.SafeGasPrice,
        proposeGasPrice: payload.result.ProposeGasPrice,
      },
    });
  } catch (error) {
    console.log("getGasEstimationPrice Error => ", error);
  }
};

/* =============================================================================  */
// In App UI functions
export const setAppLoading = (flag) => {
  console.log("coming here setAppLoading");
  return async (dispatch) => {
    dispatch({ type: web3Types.SET_CHAIN_DATA, payload: { appLoading: flag } });
  };
};

export const setFirstAppLoading = (flag) => {
  console.log("coming here setFirstAppLoading");
  return async (dispatch) => {
    dispatch({
      type: web3Types.SET_CHAIN_DATA,
      payload: { firstAppLoading: flag },
    });
  };
};

export const setCurrentNetwork = (network) => {
  return async (dispatch) => {
    dispatch({
      type: web3Types.SET_CURRENT_NETWORK,
      payload: network,
    });
  };
};

export const setActiveTrustWallet = (payload) => async (dispatch) => {
  try {
    console.log("setActiveTrustWallet start");
    dispatch({
      type: web3Types.ACTIVE_TRUST_WALLET,
      payload,
    });
  } catch (error) {
    console.log("setActiveTrustWallet Error => ", error);
  }
};

export const getTokenBalance = (account) => async (dispatch) => {
  console.log("getTokenBalance start => ", account);
  try {
    let balance = await contract.getBalance(account);

    if (balance) {
      dispatch({
        type: web3Types.SET_TOKEN_BALANCE,
        payload: balance,
      });
    }
  } catch (error) {
    console.log("getTokenBalance error => ", error);
  }
};
