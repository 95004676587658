export const DAFI_ADDRESS_POLYGON_MUMBAI =
  "0xe61B223100676D6Ffa588C7c6720348DCAD3131F";

export const NETWORK_DEMAND_POLYGON_MUMBAI =
  "0xF487571686c6Dc5c905409572D28f58DEC8aB4DB";

export const STAKING_DATABASE_POLYGON_MUMBAI =
  "0xd2A871713509843c0dcbA26eF7885986EDD767df";

export const STAKING_MANAGER_POLYGON_MUMBAI =
  "0x49122e3E5C30577799aaA480D097Cb2940a8faF3";

// End

// App Mainnet
export const TOKEN_ADDRESS_POLYGON_MAINNET =
  "0x01d35cbC2070a3B76693Ce2b6364Eae24eb88591";

export const NETWORK_DEMAND_POLYGON_MAINNET =
  "0x6A4B3FE069b69C0a2094Ce711B0CDCa9c9C844bF";

export const STAKING_DATABASE_POLYGON_MAINNET =
  "0x5e80F3E01b3A5e61E481F9b81E17FA697727C757";

export const STAKING_MANAGER_POLYGON_MAINNET =
  "0x6984Ed42C147Da5CeeF93D08f6eA7c0Cc13b5C34";
//

export const API_BASE_URL = "https://pgen-server.superstaking.io";
export const API_DEV_BASE_URL = "34.201.210.52:3000";
export const GRAPH_BASE_URL =
  "https://api.thegraph.com/subgraphs/name/pro123908";
