import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CardContent,
  makeStyles,
  Typography,
} from "@material-ui/core";

import FilterButton from "../Buttons/FilterButton";

import BasicTable from "../Table";
import CustomCard from "../Cards/CustomCard";
import { APP_TOKEN_NAME, COUNT_PER_PAGE } from "../../utils/constants";
import { useSelector } from "react-redux";
import {
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
} from "@material-ui/icons";

const TransactionCard = () => {
  const classes = useStyles();

  const [filters, setFilters] = useState([true, false, false]);

  const [filter, setFilter] = useState("Stake");

  const [txLength, setTxLength] = useState(0);

  const [trimmedHistory, setTrimmedHistory] = useState([]);
  const [currentPageCount, setCurrentPageCount] = useState(0);

  const { transactionsHistory } = useSelector((state) => state.web3.data);

  const [filteredArray, setFilteredArray] = useState([]);

  useEffect(() => {
    setFilteredArray(
      transactionsHistory.filter((item) => item.type === filter)
    );
    setCurrentPageCount(0);
  }, [transactionsHistory, filter]);

  useEffect(() => {
    if (filteredArray.length > 0) {
      setTrimmedHistory(() =>
        filteredArray.slice(
          currentPageCount * COUNT_PER_PAGE,
          COUNT_PER_PAGE * (currentPageCount + 1)
        )
      );
    }
  }, [filteredArray]);

  useEffect(() => {
    setTxLength(filteredArray.length);
  }, [filteredArray]);

  const getPrevTrimmedArrayElements = () => {
    console.log("coming => ", { currentPageCount, trimmedHistory });
    if (filteredArray.length > 0) {
      setTrimmedHistory(() =>
        filteredArray.slice(
          (currentPageCount - 1) * COUNT_PER_PAGE,
          COUNT_PER_PAGE * currentPageCount
        )
      );
      setCurrentPageCount((val) => val - 1);

      // 0 - 5
      // 5 - 10
      // 10 -15
    }
  };

  const getNextTrimmedArrayElements = () => {
    console.log("coming => ", { currentPageCount, trimmedHistory });
    if (filteredArray.length > 0) {
      setTrimmedHistory(() =>
        filteredArray.slice(
          (currentPageCount + 1) * COUNT_PER_PAGE,
          COUNT_PER_PAGE * (currentPageCount + 2)
        )
      );
      setCurrentPageCount((val) => val + 1);

      // 0 - 5
      // 5 - 10
      // 10 -15
    }
  };

  return (
    <CustomCard customStyles={classes.card}>
      <CardContent className={classes.cardContent}>
        <Box
          justifyContent="space-between"
          flexDirection="row"
          display="flex"
          marginBottom={"24px"}
          className={classes.header}
        >
          <Typography className={classes.text}>
            Transactions ({txLength})
          </Typography>
          <Box className={classes.filterButtonsContainer}>
            <FilterButton
              text={`Stake ${APP_TOKEN_NAME}`}
              active={filters[0]}
              onClick={() => {
                setFilter("Stake");

                setFilters([true, false, false]);
              }}
            />
            <FilterButton
              text={`Unstake ${APP_TOKEN_NAME}`}
              active={filters[1]}
              onClick={() => {
                setFilter("Unstake");
                setFilters([false, true, false]);
              }}
            />
            <FilterButton
              text="Claim"
              active={filters[2]}
              onClick={() => {
                setFilter("Claim");
                setFilters([false, false, true]);
              }}
            />
          </Box>
        </Box>

        <BasicTable
          filter={filter}
          setTxLength={setTxLength}
          transactions={trimmedHistory}
          txNo={COUNT_PER_PAGE * currentPageCount}
        />

        <Box className={classes.paginationContainer}>
          <Button
            className={classes.paginationBtn}
            onClick={getPrevTrimmedArrayElements}
            disabled={currentPageCount === 0}
          >
            <ArrowBackIosOutlined className={classes.paginationIcon} />
          </Button>
          <Typography className={classes.paginationText}>
            {currentPageCount + 1} of{" "}
            {filteredArray?.length <= COUNT_PER_PAGE
              ? "1"
              : Math.round(filteredArray?.length / COUNT_PER_PAGE)}
          </Typography>
          <Button
            className={classes.paginationBtn}
            onClick={getNextTrimmedArrayElements}
            disabled={
              Math.round(filteredArray?.length / COUNT_PER_PAGE) ===
              currentPageCount + 1
            }
          >
            <ArrowForwardIosOutlined className={classes.paginationIcon} />
          </Button>
        </Box>
      </CardContent>
    </CustomCard>
  );
};

export default TransactionCard;

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: 16,
    marginRight: 16,

    [theme.breakpoints.down("sm")]: {
      padding: 16,

      height: 500,
      marginRight: 0,
      paddingBottom: 6,
    },

    paddingBottom: 6,
  },

  text: {
    color: "rgba(255,255,255,.8)",
    fontSize: "18px",
    lineHeight: "24px",
    marginBottom: 12,
  },

  cardContent: {
    display: "flex",
    flexDirection: "column",
    overflowY: "scroll",
    border: 0,
    padding: 0,
    paddingBottom: "0 !important",
  },

  header: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginBottom: 12,
    },
  },

  paginationContainer: {
    padding: "0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    [theme.breakpoints.down("600")]: {
      padding: "5px 0",
    },

    height: 50,
  },

  paginationText: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "24px",
    color: "rgba(255,255,255,.8)",
    margin: "0 5px",
  },
  paginationBtn: {
    minWidth: "auto",
  },

  paginationIcon: {
    color: "#fff",
    fontSize: 16,
  },
}));
