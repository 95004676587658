import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

const Text = ({
  text,
  customStyles,
  color,
  fontSize,
  lineHeight,
  fontWeight,
  textAlign,
  marginBottom,
}) => {
  const classes = useStyles({
    color,
    fontSize,
    lineHeight,
    fontWeight,
    textAlign,
    marginBottom,
  });
  return (
    <Typography className={[classes.text, customStyles]}>{text}</Typography>
  );
};

export default Text;

const useStyles = makeStyles((theme) => ({
  text: {
    color: ({ color }) => (color ? color : theme.palette.textColor),
    fontSize: ({ fontSize }) => (fontSize ? fontSize : "18px"),
    lineHeight: ({ lineHeight }) => (lineHeight ? `${lineHeight}px` : "24px"),
    fontWeight: ({ fontWeight }) => (fontWeight ? fontWeight : "500"),
    textAlign: ({ textAlign }) => textAlign && textAlign,
    marginBottom: ({ marginBottom }) => marginBottom && marginBottom,
  },
}));
