import { createTheme } from "@material-ui/core";
import { APP_CARD_BG_COLOR, APP_FONT_NAME } from "./utils/constants";

export const theme = createTheme({
  typography: {
    fontFamily: APP_FONT_NAME,
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    color: "red",
  },

  palette: {
    cardBgColor:
      "linear-gradient(142.4deg, #380490 1.19%, #343434 114.83%), linear-gradient(142.4deg, #302B63 -8.44%, #302B63 57.82%, #24243E 114.83%);",
    snackbarColor:
      "linear-gradient(182.75deg,rgba(96, 83, 132, 0.7) -94.02%,rgba(15, 8, 36, 7) 153.25%)",

    themeColor1: "#F1973F",
    themeColor1RGBA: "241, 151, 63",
    themeGradient1:
      "linear-gradient(0deg, #F1973F, #F1973F), linear-gradient(142.4deg, #380490 1.19%, #343434 114.83%), linear-gradient(107.63deg, #302B63 -2.79%, #302B63 103.77%)",
    themeBoxShadow1:
      "inset 0px -48.3961px 40.1333px -37.7725px rgba(255, 255, 255, 0.12)",
    border: "2px solid rgba(255, 255, 255, 0.1)",

    textGradient1: "linear-gradient(94.63deg, #C881FF -3.37%, #FF4E80 89.21%)",
    textColor: "rgba(255,255,255,.8)",
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: "none",
      },
    },
  },
});
