export const STORE_WEB3_CONTEXT = "STORE_WEB3_CONTEXT";
export const SET_CHAIN_DATA = "SET_CHAIN_DATA";

export const SET_NETWORK = "SET_NETWORK";

export const SET_CURRENT_NETWORK = "SET_CURRENT_NETWORK";

export const SET_GAS_PRICE = "SET_GAS_PRICE";

export const RESET_DATA = "RESET_DATA";

export const ACTIVE_TRUST_WALLET = "ACTIVE_TRUST_WALLET";
export const SET_LEADERBOARD_DATA = "SET_LEADERBOARD_DATA";

export const SET_TOKEN_BALANCE = "SET_TOKEN_BALANCE";
